// $huge-button-size: 16em;
.#{$prefix}-ui-playbacktoggle-overlay {
    // position: relative;
    // width: 100%;
    // height: 100%;

    & > .#{$prefix}-container-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: 0;
        width: 100%;
        height: 100%;
    }


    .#{$prefix}-ui-hugeplaybacktogglebutton {

        // align to center of parent
        &,
        &:hover,
        &:focus {
            box-shadow: none !important;
        }
        display: block;
        box-shadow: none;
        position: absolute;
        top: 155px;
        right: 155px;
        bottom: 155px;
        left: 155px;
        margin: auto;
        height: calc(100% - 310px);
        width: calc(100% - 310px);
    }

}