@import '../variables';

%ui-button {
  @extend %ui-component;

  background-color: transparent;
  background-origin: content-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 1.5em;
  border: 0;
  box-sizing: content-box;
  cursor: pointer;
  font-size: 1em;
  height: 1.5em;
  min-width: 1.5em;
  padding: .25em;

  .#{$prefix}-label {
    color: $color-primary;
    display: none;
  }

  &.#{$prefix}-disabled {
    cursor: default;

    &,
    > * {
      pointer-events: none;
    }

    .#{$prefix}-label {
      &:hover {
        text-decoration: none;
      }
    }
  }

  @include hidden;
  @include focusable;
}

.#{$prefix}-ui-button {
  @extend %ui-button;
}
