.#{$prefix}-ui-subtitle-overlay {
  &.#{$prefix}-cea608 {

    bottom: 2em;
    left: 3em;
    right: 3em;
    top: 2em;

    .#{$prefix}-subtitle-region-container.#{$prefix}-subtitle-position-default {
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
    }

    .#{$prefix}-ui-subtitle-label {
      // font-family: 'Courier New', Courier, 'Nimbus Mono L', 'Cutive Mono', monospace;
      line-height: 1em;
      position: absolute;
      text-transform: uppercase;

      // sass-lint:disable force-pseudo-nesting nesting-depth
      &:nth-child(1n-1)::after {
        content: normal;
        white-space: normal;
      }
    }

    &.#{$prefix}-controlbar-visible {
      // Disable the make-space-for-controlbar mechanism
      // We don't want CEA-608 subtitles to make space for the controlbar because they're
      // positioned absolutely in relation to the video picture and thus cannot just move
      // somewhere else.
      bottom: 2em;
      transition: none;
    }
  }
}
