@import '../variables';

.#{$prefix}-ui-playbacktimelabel {
  @extend %ui-label;

  text-transform: uppercase;

  &.#{$prefix}-ui-playbacktimelabel-live {
    cursor: pointer;

    &::before {
      color: $color-secondary;
      content: '●';
      padding-right: .2em;
    }

    &.#{$prefix}-ui-playbacktimelabel-live-edge {
      &::before {
        color: $color-red;
      }
    }
  }
}
