@import '../variables';

%ui-settings-panel-page {
  display: none;

  &.#{$prefix}-active {
    display: block;
  }

  // A "line" in the panel: a container holding a label + control
  .#{$prefix}-container-wrapper > * {
    // Labels
    &.#{$prefix}-ui-label {
      display: inline-block;
      font-size: .8em;
      width: 45%;
    }

    // Controls (e.g. selectbox)
    &.#{$prefix}-ui-selectbox {
      margin-left: 10%;
      width: 45%;
    }
  }

  .#{$prefix}-ui-settings-panel-item {
    border-bottom: 1px solid transparentize($color-secondary, .7);
    padding: .5em 0;
    white-space: nowrap;

    &.#{$prefix}-last {
      border-bottom: 0;
    }

    &.#{$prefix}-hidden {
      display: none;
    }
  }
}

.#{$prefix}-ui-settings-panel-page {
  @extend %ui-settings-panel-page;
}
