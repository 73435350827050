@import 'variables';
@import 'mixins';

.#{$prefix}-ui-skin-tv {
  $overscan-margin: 2%;
  $large-size: 3.5vh;
  $medium-large-size: 3vh;
  $medium-size: 2vh;

  font-size: $medium-size;

  .#{$prefix}-ui-titlebar {
    > .#{$prefix}-container-wrapper {
      margin: $overscan-margin $overscan-margin $medium-size;
      width: 100% - 2 * $overscan-margin;
    }
  }

  .#{$prefix}-ui-titlebar-top {
    margin-bottom: 1vh;

    > .#{$prefix}-container-wrapper {
      align-items: center;
      display: inline-flex;
      font-size: $large-size;

      // Start from the right side
      justify-content: flex-end;
      width: 100%;

      .#{$prefix}-label-metadata-title {
        font-size: $large-size;

        // Moves rest of the items starting with this one to the very left
        // margin auto will fill up the rest of the space in between.
        margin-right: auto;
      }
    }
  }

  .#{$prefix}-ui-hugeplaybacktogglebutton {
    > .#{$prefix}-image {
      background-size: 20vh;
    }
  }

  .#{$prefix}-ui-titlebar-bottom {
    > .#{$prefix}-container-wrapper {
      align-items: flex-start;
      display: inline-flex;
      font-size: $large-size;
      justify-content: space-between;
      position: relative;
      width: 100%;
    }

    .#{$prefix}-label-metadata-description {
      font-size: $medium-size;
      line-height: 2em;
      width: 66%;
    }

    // Position the settings panel top right below the settings icons
    .#{$prefix}-ui-settings-panel {
      font-size: $medium-large-size;
      right: 0;
      top: 0;
    }
  }

  // Increase icon sizes and texts in the controlbar such as current time
  .#{$prefix}-ui-subtitlesettingstogglebutton,
  .#{$prefix}-ui-audiotracksettingstogglebutton {
    font-size: 5vh;
  }

  .#{$prefix}-ui-controlbar {
    font-size: $medium-large-size;

    > .#{$prefix}-container-wrapper {
      // Increase margin area around the UI but spread the dark shadow still 
      // to the edge of the video by applying it to only the container-wrapper

      margin: $overscan-margin 0;
      width: 99%;
    }
  }

  .#{$prefix}-ui-playbacktimelabel {
    font-size: 2.5vh;
  }

  :focus {
    border: 0;
    box-shadow: $focus-element-box-shadow;
    filter: drop-shadow(0 0 .3em $color-highlight);
    outline: none;
    transition: all .05s ease-in-out;
  }
}
