@import '../variables';
@import '../mixins';

.#{$prefix}-ui-uicontainer {
  @extend %ui-container;

  @include layout-cover;

  font-size: 1em;
  overflow: hidden;
  pointer-events: none; // Do not catch pointer events, pass them through

  * {
    pointer-events: auto;
  }

  &.#{$prefix}-hidden {
    // Most hiding within the UI works through the "visibility" property, because "display" cannot be animated.
    // For the outermost UI container we use "display" though, to not block any events (e.g. click events) on the video
    // when the UI is hidden.
    display: none;
  }

  // sass-lint:disable force-element-nesting
  &.#{$prefix}-player-state-playing.#{$prefix}-controls-hidden {
    // Hide cursor while the controls are hidden
    * {
      cursor: none;
    }
  }

  &.#{$prefix}-controls-shown {
    .#{$prefix}-ui-hugeplaybacktogglebutton {
      &:focus {
        box-shadow: inset -4px -3px 2px 9px $color-focus;
      }

      &:focus:not(.#{$prefix}-focus-visible) {
        box-shadow: none;
      }
    }
  }

  // IE9 compatibility: set transparent 1x1 pixel png background image to make it capture mouse events (IE9 does not capture events in areas without image or color content)
  // We abuse the no-flexbox class which is only set in IE9 (of all supported browsers)
  &.#{$prefix}-no-flexbox {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=');

    // Fullscreen legacy mode for IE9 needs additional special care to get UI visible and spanned over viewport
    &.#{$prefix}-fullscreen {
      left: 0;
      position: fixed;
      top: 0;
      z-index: 999999; // render UI above player
    }
  }
}
