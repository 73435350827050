// demo for extracted audio tracks and subtitle settings from the settings panel direct into the controlBar
@import '../variables';

.#{$prefix}-ui-audiotracksettingstogglebutton {
  @extend %ui-settingstogglebutton;

  background-image: url('../../assets/audio-tracks.svg');

  &.#{$prefix}-on {
    background-image: url('../../assets/audio-tracksX.svg');
  }
}
