@mixin subtitles-block {
  display: block !important;
  padding: var(--subtitles-padding) !important;
  background-color: rgba(0, 0, 0, 0.75) !important;
  border-radius: 10px !important;
  margin: 0 auto !important;
}

@mixin tv-variables {
  &.#{$prefix}-cw {
    --subtitles-font-size: 2.2rem;
    --controls-visible-bottom: 156px;
    --controls-hidden-bottom: 78px;
  }
}
.#{$prefix}-ui-uicontainer {
  .#{$prefix}-ui-subtitle-overlay {
    --subtitles-padding: 0.25em 0.75em;
    --subtitles-font-size: 0.75rem;
    --controls-hidden-bottom: 10%;
    --controls-visible-bottom: 76px;
    @include tv-variables;
    position: absolute;
    top: auto;
    right: 0;
    bottom: var(--controls-hidden-bottom);
    left: 0;
    outline: 0;
    pointer-events: none;
    text-align: center;
    -webkit-transition: bottom 0.15s ease-out;
    transition: bottom 0.15s ease-out;
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    font-size: var(--subtitles-font-size);
    * {
      all: unset;
    }
    p {
      display: block;
    }

    &.#{$prefix}-controlbar-visible {
      bottom: var(--controls-visible-bottom);
      -webkit-transition: bottom 0.15s ease-in;
      transition: bottom 0.15s ease-in;
    }
    .#{$prefix}-ui-subtitle-label {
      color: $subtitle-text-color;
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
      text-shadow: -1px -1px 0 #000, 0 -1px 0 #000, 1px -1px 0 #000, -1px 0 0 #000, 1px 0 0 #000, -1px 1px 0 #000,
        0 1px 0 #000, 1px 1px 0 #000;
      position: static !important;
      line-height: 1em;
      text-transform: uppercase;
      top: unset !important;
      bottom: unset !important;
      left: unset !important;
      right: unset !important;
      display: flex !important;
      flex-direction: row !important;
      text-align: center !important;
      font-size: var(--subtitles-font-size) !important;
      line-height: 1.25;
      &:nth-child(1n-1) {
        &:after {
          content: '\a';
          height: 0;
          white-space: pre-line;
          width: 0;
        }
      }
    }

    .#{$prefix}-subtitle-region-container {
      width: 100%;
      position: relative;
      &.#{$prefix}-subtitle-position-vtt {
        .#{$prefix}-ui-subtitle-label {
          margin: 0 auto;
          & > span {
            @include subtitles-block;
          }
        }
      }
      &.#{$prefix}-subtitle-position-default {
        display: flex;
        justify-content: center;
        & > .#{$prefix}-container-wrapper {
          @include subtitles-block;
        }
      }
    }

    &.#{$prefix}-hidden {
      display: none;
    }

    @media screen and (min-width: 420px) {
      --subtitles-font-size: 1rem;
      @include tv-variables;
    }

    @media screen and (min-width: 576px) {
      --subtitles-font-size: 1.2rem;
      --subtitles-padding: 0.325em 1em;
      @include tv-variables;
    }

    @media screen and (min-width: 1024px) {
      --subtitles-font-size: 1.5rem;
      --controls-visible-bottom: 96px;
      --controls-hidden-bottom: 48px;
      @include tv-variables;
    }
  }
  * {
    pointer-events: auto;
  }
  .#{$prefix}-text-right {
    text-align: right;
  }
}
