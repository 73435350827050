@import '../variables';

%ui-container {
  @extend %ui-component;

  font-size: 1em;
}

.#{$prefix}-ui-container {
  @extend %ui-container;
}
