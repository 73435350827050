@import 'variables';

.#{$prefix}-ui-skin-cast-receiver {

  $background-color: transparentize($color-background, .15);
  $overscan-margin: 10%;

  // Add title safe margins for overscan TV sets
  // 10% as recommended by the Cast docs: https://developers.google.com/cast/docs/custom_receiver#overscan
  &.#{$prefix}-ui-uicontainer {

    %box {
      background: $background-color;
      padding: .3em .5em;
    }

    .#{$prefix}-ui-controlbar {
      @extend %box;

      bottom: $overscan-margin;
      left: $overscan-margin;
      right: $overscan-margin;
      width: auto;
    }

    .#{$prefix}-ui-titlebar {
      @extend %box;

      left: $overscan-margin;
      right: $overscan-margin;
      top: $overscan-margin;
      width: auto;
    }

    .#{$prefix}-ui-watermark {
      margin: 0;
      right: $overscan-margin;
      top: $overscan-margin;
    }

    .#{$prefix}-ui-subtitle-overlay {
      bottom: $overscan-margin;

      &.#{$prefix}-controlbar-visible {
        bottom: 20%;
      }

      // Replace the shadow-based text border with a transparent backdrop for better rendering
      // performance on Chromecast.
      .#{$prefix}-ui-subtitle-label {
        background-color: transparentize($subtitle-text-border-color, .5);
        box-decoration-break: clone;
        display: inline-flex;
        line-height: 2em;
        padding: .3em .5em;
        text-shadow: none;
      }
    }

    // Chromecast (v1) has very poor rendering performance, so we disable the animations.
    // We also display a pause image while playback is paused, instead of a play action button
    .#{$prefix}-ui-hugeplaybacktogglebutton {
      .#{$prefix}-image {
        background-image: url('../assets/pause.svg');
        opacity: .7;
      }

      // sass-lint:disable force-element-nesting
      &.#{$prefix}-on .#{$prefix}-image,
      &.#{$prefix}-off .#{$prefix}-image {
        animation: none;
        transition: none;
      }
    }

    // Disable transitions due to poor Chromecast performance
    .#{$prefix}-seekbar-bufferlevel,
    .#{$prefix}-seekbar-playbackposition,
    .#{$prefix}-ui-buffering-overlay,
    .#{$prefix}-ui-titlebar,
    .#{$prefix}-ui-controlbar,
    .#{$prefix}-ui-subtitle-overlay {
      transition: none;
    }

    // Hide animated buffering indicators due to poor Chromecast performance
    .#{$prefix}-ui-buffering-overlay-indicator {
      display: none;
    }
  }
}
