// demo for extracted audio tracks and subtitle settings from the settings panel direct into the controlBar
@import '../variables';

.#{$prefix}-ui-subtitlesettingstogglebutton {
  @extend %ui-settingstogglebutton;

  background-image: url('../../assets/subtitles.svg');

  &.#{$prefix}-on {
    background-image: url('../../assets/subtitlesX.svg');
  }
}
