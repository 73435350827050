@import '../variables';

%ui-component {
  /*! placeholder to avoid removal of empty selector */
  //outline: 1px solid red;
  outline: 0;
}

.#{$prefix}-ui-component {
  @extend %ui-component;
}
