@import '../variables';

%ui-label {
  @extend %ui-component;

  @include hidden;

  cursor: default;
  white-space: nowrap;
}

.#{$prefix}-ui-label {
  @extend %ui-label;
}

.#{$prefix}-ui-label-savedEnergy {
  @extend %ui-label;
  font-size: 0.8em;
  color: #1fabe2;
  margin-left: 2.2em;
}
