$prefix: 'bmpui';

$color-black: #000;
$color-transparent: rgba(0, 0, 0, 0);
$color-red: #f00;

$color-highlight: #1fabe2; //Bitmovin blue
$color-primary: #fff;
$color-secondary: #999;

$color-background: #111;
$color-background-highlight: transparentize(mix($color-black, $color-highlight, 75%), .3);
$color-background-bars: transparentize($color-black, .3);
$color-focus: #1b7fcc;

$font-family: '__national2_c05267','__national2_Fallback_c05267', sans-serif;
$font-size: 1em;

$subtitle-text-color: #fff;
$subtitle-text-border-color: #000;

$animation-duration: .3s;
$animation-duration-short: $animation-duration * .5;

$focus-element-box-shadow: 0 0 0 2px rgba($color-focus, .8);
