@import '../variables';
@import '../mixins';

.#{$prefix}-ui-piptogglebutton {
  @extend %ui-button;

  background-image: url('../../assets/picinpic1.svg');

  &:hover {
    @include svg-icon-shadow;
  }

  &.#{$prefix}-on {
    background-image: url('../../assets/picinpic1X.svg');
  }
}
