@import '../variables';
@import '../mixins';

.#{$prefix}-ui-volumecontrolbutton {
  @extend %ui-container;

  line-height: 0; // Fix layout for Firefox: removes spurious space in the container
  position: relative;

  .#{$prefix}-ui-volumeslider {
    @include animate-slide-in-from-bottom(6em, $animation-duration-short);

    background-color: $color-background;
    bottom: 100%;
    height: 6em;
    position: absolute;
    width: 1.5em;

    .#{$prefix}-seekbar {
      bottom: .5em;
      height: auto;
      left: .3em;
      overflow: hidden;
      position: absolute;
      right: .3em;
      top: .5em;
      width: auto;
    }
  }
}
