@import './partials/variables';
@import './partials/mixins';
@import './partials/components/component';
@import './partials/components/container';
@import './partials/components/uicontainer';
@import './partials/components/controlbar';
@import './partials/components/button';
@import './partials/components/playbacktogglebutton';
@import './partials/components/quickseekbutton';
@import './partials/components/fullscreentogglebutton';
@import './partials/components/vrtogglebutton';
@import './partials/components/volumetogglebutton';
@import './partials/components/ecomodetogglebutton';
@import './partials/components/seekbar';
@import './partials/components/watermark';
@import './partials/components/hugeplaybacktogglebutton';
@import './partials/components/label';
@import './partials/components/settingspanel';
@import './partials/components/settingspanelpage';
@import './partials/components/settingspanelpageopenbutton';
@import './partials/components/settingspanelpagebackbutton';
@import './partials/components/settingstogglebutton';
@import './partials/components/selectbox';
@import './partials/components/listbox';
@import './partials/components/seekbarlabel';
@import './partials/components/volumeslider';
@import './partials/components/subtitleoverlay';
@import './partials/components/subtitleoverlay-cea608';
@import './partials/components/volumecontrolbutton';
@import './partials/components/casttogglebutton';
@import './partials/components/caststatusoverlay';
@import './partials/components/errormessageoverlay';
@import './partials/components/titlebar';
@import './partials/components/recommendationoverlay';
@import './partials/components/clickoverlay';
@import './partials/components/hugereplaybutton';
@import './partials/components/replaybutton';
@import './partials/components/playbacktimelabel';
@import './partials/components/bufferingoverlay';
@import './partials/components/playbacktoggleoverlay';
@import './partials/components/closebutton';
@import './partials/components/airplaytogglebutton';
@import './partials/components/pictureinpicturetogglebutton';
@import './partials/components/spacer';
@import './partials/components/subtitlesettings/subtitlesettings';
@import './partials/components/subtitlesettingspaneltogglebutton';
@import './partials/components/audiotracksettingspaneltogglebutton';
@import './partials/skin-ads';
@import './partials/skin-cast-receiver';
@import './partials/skin-smallscreen';
@import './partials/skin-tv';

.#{$prefix}-ui-uicontainer {
  color: $color-primary;
  font-family: $font-family;
  font-size: $font-size;
  text-align: left;
  user-select: none;

  &.#{$prefix}-player-state-idle {
    .#{$prefix}-ui-controlbar,
    .#{$prefix}-ui-titlebar,
    .#{$prefix}-ui-hugeplaybacktogglebutton {
      display: none;
    }
  }

  &.#{$prefix}-player-state-finished {
    .#{$prefix}-ui-controlbar,
    .#{$prefix}-ui-hugeplaybacktogglebutton {
      display: none;
    }
  }

  .#{$prefix}-text-right {
    text-align: right;
  }

  // sass-lint:disable force-element-nesting
  &.#{$prefix}-layout-max-width-400 {
    // Hide the description in ultra tiny players to not clog the UI too much
    .#{$prefix}-ui-titlebar .#{$prefix}-label-metadata-description {
      display: none;
    }
  }

  &.#{$prefix}-layout-max-width-400,
  &.#{$prefix}-layout-max-width-600 {
    .#{$prefix}-ui-hugeplaybacktogglebutton {
      font-size: .7em;
    }

    .#{$prefix}-ui-watermark {
      font-size: .7em;
    }
  }
}
