@import '../variables';
@import '../mixins';

.#{$prefix}-ui-quickseekbutton {
  @extend %ui-button;

  &:hover {
    @include svg-icon-shadow;
  }

  &[data-#{$prefix}-seek-direction='forward'] {
    // background-image: url('../assets/quickseek-fastforward.svg');
    background-image: url('../../assets/quickseek-fastforward.svg');
  }

  &[data-#{$prefix}-seek-direction='rewind'] {
    background-image: url('../../assets/quickseek-rewind.svg');
  }
}
