@import '../variables';
@import '../mixins';

.#{$prefix}-ui-volumetogglebutton {
  @extend %ui-button;

  &:hover {
    @include svg-icon-shadow;
  }

  &.#{$prefix}-muted {
    background-image: url('../../assets/music-off.svg');
  }

  &.#{$prefix}-unmuted {
    &[data-#{$prefix}-volume-level-tens='0'] {
      background-image: url('../../assets/music-off.svg');
    }

    &[data-#{$prefix}-volume-level-tens='1'],
    &[data-#{$prefix}-volume-level-tens='2'],
    &[data-#{$prefix}-volume-level-tens='3'],
    &[data-#{$prefix}-volume-level-tens='4'],
    &[data-#{$prefix}-volume-level-tens='5'] {
      background-image: url('../../assets/music-low.svg');
    }

    &[data-#{$prefix}-volume-level-tens='6'],
    &[data-#{$prefix}-volume-level-tens='7'],
    &[data-#{$prefix}-volume-level-tens='8'],
    &[data-#{$prefix}-volume-level-tens='9'],
    &[data-#{$prefix}-volume-level-tens='10'] {
      background-image: url('../../assets/music-on.svg');
    }
  }
}
