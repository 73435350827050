@import '../variables';

%ui-settingspanelpagebackbutton {
  @extend %ui-button;

  font-size: .8em;
  position: relative;
  width: 8em;

  .#{$prefix}-label {
    display: inline-block;

    &::before {
      border-bottom: .2em solid $color-primary;
      border-left: .2em solid $color-primary;
      content: '';
      height: .6em;
      margin-left: -.8em;
      position: absolute;
      top: .6em;
      transform: rotate(45deg);
      width: .6em;
    }
  }
}

.#{$prefix}-ui-settingspanelpagebackbutton {
  @extend %ui-settingspanelpagebackbutton;
}
